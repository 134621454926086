import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  Filter,
  TextInput,
  NumberField,
  FunctionField,
  EditButton,
} from 'react-admin';

const CompaniesFilters = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="all" alwaysOn />
  </Filter>
);

function CompaniesList(props: any) {
  return (
    <List {...props} filters={<CompaniesFilters />} hasShow hasEdit>
      <Datagrid>
        <TextField source="name" />
        <TextField label="Wallet" source="wallet.credits" />
        <FunctionField label="Owner" render={record => `${record.owner?.firstName} ${record.owner?.lastName}`} />
        <TextField label="Owner email" source="owner.email" />
        <NumberField label="Workers count" source="customersCount" />
        <NumberField label="Emissions" source="totalEmission" />
        <DateField source="createdAt" label="Register at" />
        <EditButton label="Manage features" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}
export default CompaniesList;
