import {
  AdminDto,
  AdminsSignInBodyDto,
  AuthAdminDto,
  CreateAdminDto,
  Admin,
  GetPaginatedCustomersResponseDto,
  CreateCustomerOwnerDto,
  UpdateCustomersPasswordDto,
} from '../types';
import { default as httpClient } from './httpClient';

export const getMany = (prefix: string, params: any) => {
  return httpClient.get(`${prefix}`, { params });
};

export const getOne = (prefix: string, id: string, params?: any) => {
  return httpClient.get(`${prefix}/${id}`, { params });
};

export const deleteOne = (prefix: string, id: string) => {
  return httpClient.delete(`${prefix}/${id}`);
};

export const updateOne = (prefix: string, id: string, body: any) => {
  return httpClient.patch(`${prefix}/${id}`, body);
};

export const login = (body: AdminsSignInBodyDto) => {
  return httpClient.post<AuthAdminDto>('admins/sign-in', body);
};

export const getAdmin = () => {
  return httpClient.get<AdminDto>('admins/me');
};

export const inviteOwner = (body: CreateCustomerOwnerDto) => {
  return httpClient.post<boolean>('admins/create-owner-customer', body);
};

export const addCredits = (body: number) => {
  return httpClient.post('admins/add-credits', body);
};

export const createAdmin = (body: CreateAdminDto) => {
  return httpClient.post<Admin>('admins', body);
};

export const changeCompanyCredits = ({ id, credits }) => {
  return httpClient.patch<boolean>(`admins/companies/${id}/credits`, { credits });
};

export const getCompanyCustomers = (id: string, params?: any) => {
  return httpClient.get<GetPaginatedCustomersResponseDto>(`admins/companies/${id}/customers`, { params });
};

export const updateCustomersPassword = (body: UpdateCustomersPasswordDto) => {
  return httpClient.patch('admins/customers/', body);
};
