import * as React from 'react';
import { CreateButton, TopToolbar } from 'react-admin';

const ListActions = props => (
  <TopToolbar className={props.className}>
    <CreateButton label="Invite Owner" />
  </TopToolbar>
);

export default ListActions;
