import React from 'react';
import { List, Datagrid, TextField, DateField, ShowButton } from 'react-admin';

function OffsetsList(props: any) {
  return (
    <List {...props} hasShow>
      <Datagrid>
        <TextField label="Company" source="company.name" />
        <TextField source="emission" />
        <TextField label="Customer" source="customer.email" />
        <DateField source="createdAt" label="Created at" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}

export default OffsetsList;
