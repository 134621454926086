import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField,
  ImageField,
  EditButton,
  TopToolbar,
  ListButton,
  DeleteButton,
  DateField,
} from 'react-admin';

const Title = ({ customer }: any) => (
  <div>Customer: {`${customer?.firstName || 'DEF_NAME'} ${customer?.lastName || 'DEF_LAST'}`}</div>
);

const Actions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ListButton />
    <DeleteButton record={data} resource={resource} mutationMode="pessimistic" />
  </TopToolbar>
);

function ShowCustomer(props: any) {
  return (
    <Show {...props} title={<Title />} actions={<Actions />} hasList basePath={props.basePath}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <TextField label="Email" source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phone" />
        <TextField label="Credits" source="wallet.credits" />
        <TextField source="status" />
        <TextField source="role" />
        <DateField source="createdAt" label="Registration date" />
      </SimpleShowLayout>
    </Show>
  );
}

export default ShowCustomer;
