import * as globalTypes from './global-types';

export type AdminsSignInBodyDto = globalTypes.components['schemas']['AdminsSignInBodyDto'];
export type AuthAdminDto = globalTypes.components['schemas']['AuthAdminDto'];
export type AdminDto = globalTypes.components['schemas']['AdminDto'];
export type Admin = globalTypes.components['schemas']['Admin'];

export type Customer = globalTypes.components['schemas']['Customer'];
export type GetManyCustomerResponseDto = globalTypes.components['schemas']['GetManyCustomerResponseDto'];
export type UpdateCustomerDto = globalTypes.components['schemas']['UpdateCustomerDto'];
export type UpdateCompanyAdminDto = globalTypes.components['schemas']['UpdateCompanyAdminDto'];
export type UpdateCustomersPasswordDto = globalTypes.components['schemas']['UpdateCustomersPasswordRequestDto'];
export type CreateCustomerDto = globalTypes.components['schemas']['CreateCustomerDto'];
export type CreateCustomerOwnerDto = globalTypes.components['schemas']['CreateCustomerOwnerDto'];
export type CreateAdminDto = globalTypes.components['schemas']['CreateAdminDto'];
export type Offset = globalTypes.components['schemas']['Offset'];
// export type CreditInCompanyDto = globalTypes.components['schemas']['CreditInCompanyDto'];

export type GetPaginatedCustomersResponseDto = globalTypes.components['schemas']['GetPaginatedCustomersResponseDto'];

export enum CompanyFeature {
  Actions = 'ACTIONS',
  Targets = 'TARGETS',
  Offsets = 'OFFSETS',
  Analytics = 'ANALYTICS',
  Accounting = 'ACCOUNTING',
  Portfolio = 'PORTFOLIO',
}

export type ResponseError = {
  error: string;
  message: string | string[];
  statusCode: number;
};
