import { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  ImageField,
  EditButton,
  ShowButton,
  Filter,
  TextInput,
  BulkDeleteButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import ListActions from '../ListActions';
import CustomersUpdatePasswordButton from '../CustomersUpdatePasswordButton';

const useStyles = makeStyles({
  avatar: {
    '& > img': {
      maxHeight: 32,
      maxWidth: 32,
      width: '100%',
      borderRadius: '50%',
    },
  },
});

const CustomersFilters = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="all" alwaysOn />
  </Filter>
);

const CustomersBulkActions = (props: any) => (
  <Fragment>
    <CustomersUpdatePasswordButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

function CustomersList(props: any) {
  const classNames = useStyles();

  return (
    <List
      {...props}
      filters={<CustomersFilters />}
      hasEdit
      hasShow
      actions={<ListActions />}
      bulkActionButtons={<CustomersBulkActions />}>
      <Datagrid>
        <ImageField source="avatarUrl" label="Avatar" className={classNames.avatar} sortable={false} />
        <EmailField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="status" />
        <TextField source="role" />
        <TextField source="phone" />
        <TextField label="Wallet" source="wallet.credits" />
        <DateField source="createdAt" label="Register at" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
}

export default CustomersList;
