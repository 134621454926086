import { ResponseError } from 'types';

export const getErrorMessage = (error: ResponseError) => {
  if (!error.message) {
    return 'Something wrong';
  }

  if (typeof error.message === 'string') {
    return error.message;
  }
  return error.message.join('\n');
};
