import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  TopToolbar,
  ShowButton,
  ListButton,
  DeleteButton,
} from 'react-admin';

export const roles = [
  { name: 'Owner', id: 'owner' },
  { name: 'Co-owner', id: 'co-owner' },
  { name: 'Customer', id: 'customer' },
  { name: 'Worker', id: 'worker' },
  { name: 'Guest', id: 'guest' },
];

export const statuses = [
  { name: 'Active', id: 'active' },
  { name: 'Suspended', id: 'suspended' },
  { name: 'Invited', id: 'invited' },
];

const Title = ({ customer }: any) => (
  <div>Customer: {`${customer?.firstName || 'DEF_NAME'} ${customer?.lastName || 'DEF_LAST'}`}</div>
);

const Actions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <ShowButton basePath={basePath} record={data} />
    <ListButton />
    <DeleteButton record={data} resource={resource} mutationMode="pessimistic" />
  </TopToolbar>
);

const EditCustomer = (props: any) => (
  <Edit {...props} title={<Title />} actions={<Actions />} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput disabled label="Email" source="email" />
      <TextInput disabled label="Credits" source="wallet.credits" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="phone" />

      <SelectInput source="role" choices={roles} />
      <SelectInput source="status" choices={statuses} />
    </SimpleForm>
  </Edit>
);

export default EditCustomer;
