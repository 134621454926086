import React, { useState, useEffect } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  ListButton,
  DeleteButton,
  DateField,
  useDataProvider,
} from 'react-admin';
import { useParams } from 'react-router-dom';

const Title = () => <div>Offset</div>;

const Actions = ({ data, resource }: any) => (
  <TopToolbar>
    <ListButton />
    <DeleteButton record={data} resource={resource} mutationMode="pessimistic" />
  </TopToolbar>
);

const header = ['Name of the organization', 'Amount of credits', 'Payment  status'];

const colStyle = { display: 'grid', gridTemplateColumns: '35% 35% 30%' };
const textStyle = { margin: 0 };

function ShowOffset(props: any) {
  const [offset, setOffset] = useState<any>(null);
  const dataProvider = useDataProvider();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    const fetchOffset = async () => {
      if (id) {
        const { data } = await dataProvider.getOne('offsets', { id });
        setOffset(data);
      }
    };

    fetchOffset();
  }, [dataProvider, id]);

  return (
    <Show {...props} title={<Title />} actions={<Actions />} hasList basePath={props.basePath}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <TextField label="Company" source="company.name" />
        <TextField label="Customer" source="customer.firstName" />
        <TextField source="emission" />
        <DateField source="createdAt" label="Registration date" />

        <div>
          <h4 style={{ margin: 0 }}>Transactions:</h4>
          <div style={{ ...colStyle, color: 'rgba(0, 0, 0, 0.54)' }}>
            {header.map(col => (
              <p key={col}>{col}</p>
            ))}
          </div>
          <div>
            {offset?.transactions?.map(trans => (
              <div key={trans.id} style={colStyle}>
                <p style={textStyle}>{trans.recipient}</p>
                <p style={textStyle}>{trans.credits} credits</p>
                <p style={textStyle}>{trans.status}</p>
              </div>
            ))}
          </div>
        </div>
      </SimpleShowLayout>
    </Show>
  );
}

export default ShowOffset;
