import { defaultTheme } from 'react-admin';

export const theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    secondary: {
      ...defaultTheme.palette.secondary,
      main: '#31caca',
    },
  },
  typography: {
    fontFamily: `"Poppins", "Helvetica"`,
  },
};
