import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useNotify,
  FormWithRedirect,
  email,
  composeValidators,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useCallback } from 'react';
import { createAdmin } from 'api';

function AddAdmin() {
  const [showDialog, setShowDialog] = useState(true);
  const notify = useNotify();

  const handleCloseClick = () => {
    setShowDialog(false);
    history.back();
  };

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await createAdmin(values);
        setShowDialog(false);
      } catch (error) {
        notify(error.message, 'error');
      }
      history.back();
    },
    [notify],
  );

  return (
    <>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Add Admin">
        <DialogTitle>Add Admin</DialogTitle>

        <FormWithRedirect
          resource="admins"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving, submitting }) => (
            <>
              <DialogContent>
                <TextInput source="firstName" validate={composeValidators(required())} fullWidth />
                <TextInput source="lastName" validate={composeValidators(required())} fullWidth />
                <TextInput source="email" validate={composeValidators(required(), email())} fullWidth />
                <TextInput
                  source="password"
                  type="password"
                  name="password"
                  validate={composeValidators(required())}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={submitting}>
                  <IconCancel />
                </Button>
                <SaveButton
                  label="Create"
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={submitting}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default AddAdmin;
