import { Fragment, ReactElement, useCallback, useState } from 'react';
import {
  required,
  Button,
  FormWithRedirect,
  SaveButton,
  composeValidators,
  useNotify,
  PasswordInput,
  ButtonProps,
  BulkActionProps,
  useResourceContext,
  useUnselectAll,
  useRefresh,
} from 'react-admin';

import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ActionUpdate from '@material-ui/icons/Update';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { updateCustomersPassword } from 'api';
import { ResponseError } from 'types';
import { getErrorMessage } from 'utils/get-error-message';

function CustomersUpdatePasswordButton(props: CustomersUpdatePasswordButtonProps) {
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const refresh = useRefresh();
  const resource = useResourceContext(props);
  const classes = useStyles(props);

  const hadnleOpenClick = e => {
    setShowDialog(true);
    e.stopPropagation();
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const { icon = defaultIcon, label = 'ra.action.update', selectedIds, ...rest } = props;

  const handleSubmit = useCallback(
    async value => {
      try {
        await updateCustomersPassword({
          password: value.password,
          customersIds: selectedIds as string[],
        });
        setShowDialog(false);
        unselectAll(resource);
        refresh();
      } catch (error) {
        const errorMessage = getErrorMessage(error as ResponseError);
        notify(errorMessage, 'error');
      }
    },
    [resource, selectedIds, notify, unselectAll, refresh],
  );

  return (
    <Fragment>
      <Button
        {...sanitizeRestProps(rest)}
        onClick={hadnleOpenClick}
        label={label}
        className={classes.updatePasswordButton}>
        {icon}
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Update password">
        <DialogTitle>Update Password</DialogTitle>

        <FormWithRedirect
          resource="customers"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving, submitting }) => (
            <>
              <DialogContent>
                <PasswordInput source="password" validate={composeValidators(required())} />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={submitting}>
                  <IconCancel />
                </Button>
                <SaveButton
                  label="Send"
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={submitting}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </Fragment>
  );
}

export interface CustomersUpdatePasswordButtonProps extends BulkActionProps, ButtonProps {
  icon?: ReactElement;
}

const sanitizeRestProps = ({
  basePath,
  classes,
  filterValues,
  label,
  ...rest
}: Omit<CustomersUpdatePasswordButtonProps, 'resource' | 'selectedIds' | 'icon'>) => rest;

const useStyles = makeStyles(
  theme => ({
    updatePasswordButton: {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'CustomersUpdatePasswordButton' },
);

const defaultIcon = <ActionUpdate />;

export default CustomersUpdatePasswordButton;
