import { GetListParams } from 'ra-core';

import * as api from '../../api';
import { nestQueryBuilder } from '../../utils/nest-query-builder';
import { RouteDataProvider } from '../types';

const route = 'admins';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const adminsDataProvider = {
  getList: async (params: GetListParams) => {
    const { data } = await api.getMany(route, nestQueryBuilder(params));
    return data;
  },
  getOne: async params => {
    return api.getOne(route, `${params.id}`);
  },
  getMany: async params => {
    const data = await Promise.all(
      params.ids.map(async id => {
        const { data } = await api.getOne(route, `${id}`);
        return data;
      }),
    );
    return { data };
  },
  delete: async params => {
    return api.deleteOne(route, `${params.id}`);
  },
  deleteMany: async params => {
    const data = await Promise.all(
      params.ids.map(async id => {
        await api.deleteOne(route, `${id}`);
      }),
    );

    return { data };
  },
  update: async params => {
    return api.updateOne(route, `${params.id}`, params.data);
  },
} as RouteDataProvider;
