import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useNotify,
  FormWithRedirect,
  email,
  composeValidators,
  CheckboxGroupInput,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { inviteOwner } from '../../../api';
import { useCallback } from 'react';
import { transformEnumToChoice } from '../../../utils/transform-enum-to-choice';
import { CompanyFeature, ResponseError } from '../../../types';
import { getErrorMessage } from 'utils/get-error-message';

function OwnerInviteButton() {
  const [showDialog, setShowDialog] = useState(true);
  const notify = useNotify();

  const handleCloseClick = () => {
    setShowDialog(false);
    history.back();
  };

  const handleSubmit = useCallback(
    async value => {
      try {
        await inviteOwner({
          email: value.email,
          companyFeatures: {
            allowed: value.allowed || [],
            maxSitesAmount: value.maxSitesAmount,
          },
        });
        setShowDialog(false);
        history.back();
      } catch (error) {
        const errorMessage = getErrorMessage(error as ResponseError);
        notify(errorMessage, 'error');
      }
    },
    [notify],
  );

  return (
    <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Invite owner">
      <DialogTitle>Invite Owner</DialogTitle>

      <FormWithRedirect
        resource="customers"
        save={handleSubmit}
        render={({ handleSubmitWithRedirect, saving, submitting }) => (
          <>
            <DialogContent>
              <TextInput source="email" validate={composeValidators(required(), email())} fullWidth />
              <CheckboxGroupInput source="allowed" choices={transformEnumToChoice(CompanyFeature)} />
              <TextInput source="maxSitesAmount" validate={composeValidators(required())} parse={val => Number(val)} />
            </DialogContent>
            <DialogActions>
              <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={submitting}>
                <IconCancel />
              </Button>
              <SaveButton
                label="Send"
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                saving={saving}
                disabled={submitting}
              />
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
}

export default OwnerInviteButton;
