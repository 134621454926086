import React from 'react';
import { List, Datagrid, TextField, DateField, TopToolbar, CreateButton } from 'react-admin';

const ListActions = props => (
  <TopToolbar className={props.className}>
    <CreateButton />
  </TopToolbar>
);

function CustomersList(props: any) {
  return (
    <List {...props} actions={<ListActions />}>
      <Datagrid>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="role" />
        <DateField source="createdAt" label="Register at" />
      </Datagrid>
    </List>
  );
}

export default CustomersList;
