import React from 'react';
import { Admin, Resource } from 'react-admin';
import {
  CompanyEdit,
  CompaniesList,
  CompaniesShow,
  CustomersEdit,
  CustomerShow,
  CustomersList,
  OffsetShow,
  OffsetsList,
  OwnerInviteButton,
} from './resources';
import dataProvider from './provider';
import { useAuth } from './hooks/useAuth';
import { AdminsList } from './resources/Admins';
import { AddAdmin } from './resources/Admins';
import { theme } from './theme';

function App() {
  const [authProvider] = useAuth();

  return (
    <Admin theme={theme} title="Net0 Admin" authProvider={authProvider} dataProvider={dataProvider}>
      <Resource
        name="customers"
        list={CustomersList}
        edit={CustomersEdit}
        show={CustomerShow}
        create={OwnerInviteButton}
      />
      <Resource name="companies" list={CompaniesList} edit={CompanyEdit} show={CompaniesShow} />
      <Resource name="offsets" list={OffsetsList} show={OffsetShow} />
      <Resource name="admins" list={AdminsList} create={AddAdmin} />
    </Admin>
  );
}

export default App;
