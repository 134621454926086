import { GetListParams } from 'ra-core';

type BaseQuery = {
  roleFilter: string;
  search: any;
  join: Array<{ tableName: string; fields: Array<string> }>;
  [key: string]: any;
} & GetListParams;

type Query = Partial<BaseQuery>;

export const nestQueryBuilder = (query: Query) => {
  const nestQuery = new URLSearchParams();

  if (query.pagination) {
    const { page, perPage } = query.pagination;
    nestQuery.append('page', `${page}`);
    nestQuery.append('limit', `${perPage}`);
  }

  if (query.sort) {
    const { field, order } = query.sort;
    const sort = `${field},${order}`;
    nestQuery.append('sort', sort);
  }

  if (query.filter) {
    const filter = query.filter;
    const filterKeys = Object.keys(filter);
    filterKeys.forEach(key => {
      nestQuery.append('filter', `${key}||$contL||${filter[key]}`);
    });
  }

  if (query.search) {
    const search = query.search;
    const searchKeys = Object.keys(search);
    const orQueries = searchKeys.map(key => ({ [key]: { $contL: `${search[key]}` } }));

    nestQuery.append('s', JSON.stringify({ $or: orQueries }));
  }

  if (query.roleFilter) {
    nestQuery.append('filter', `role||$eq||${query.roleFilter}`);
  }

  if (query.join) {
    query.join.forEach(joinField => {
      const { tableName, fields } = joinField;
      const joinQuery = `${tableName}||${fields.join(',')}`;
      nestQuery.append('join', joinQuery);
    });
  }
  return nestQuery;
};
