import React, { useState } from 'react';
import { required, Button, SaveButton, TextInput, useNotify, FormWithRedirect, useDataProvider } from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { changeCompanyCredits } from '../../../api';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

function AddCreditsButton() {
  const dataProvider = useDataProvider();
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const { id } = useParams<{ id?: string }>();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = useCallback(
    async values => {
      try {
        if (id) {
          await changeCompanyCredits({ id: parseInt(id), credits: parseFloat(values.credits) });
          await dataProvider.getOne('companies', { id });
        }
        setShowDialog(false);
      } catch (error) {
        notify(error.message, 'error');
      }
    },
    [dataProvider, id, notify],
  );

  return (
    <>
      <Button onClick={handleClick} label="Add credits">
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Add credits">
        <DialogTitle>Add credits</DialogTitle>

        <FormWithRedirect
          resource="companies"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving, submitting }) => (
            <>
              <DialogContent>
                <TextInput type="number" source="credits" validate={required()} fullWidth />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={submitting}>
                  <IconCancel />
                </Button>
                <SaveButton
                  label="Add"
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={submitting}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default AddCreditsButton;
