import React, { useMemo } from 'react';
import { AuthProvider } from 'react-admin';
import { getAdmin, login } from '../api';
import StorageService from '../services/StorageService';

export const useAuth = () => {
  const authProvider = useMemo<AuthProvider>(() => {
    return {
      login: async ({ username, password }) => {
        try {
          const response = await login({ email: username, password });

          if (response.data.user) {
            StorageService.setItem('accessToken', response.data.token);
          }

          return response;
        } catch (error) {
          console.log('Error', error.message);
        }
      },

      checkAuth: async () =>
        localStorage.getItem('accessToken') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' }),
      checkError: (error: any) => {
        return new Promise((resolve, reject) => {
          resolve();
        });
      },

      getPermissions: props => {
        return new Promise(resolve => resolve(false));
      },
      logout: async () => {
        StorageService.removeItem('accessToken');
      },
      getIdentity: async () => {
        try {
          const { data } = await getAdmin();

          return data;
        } catch (error) {
          throw new Error('Failed get admin');
        }
      },
    };
  }, []);

  return [authProvider] as const;
};
