import { DataProvider } from 'ra-core';
import {
  adminsDataProvider,
  customersDataProvider,
  companiesDataProvider,
  offsetsDataProvider,
} from './routesProviders';
import { RouteDataProvider } from './types';

const resourceProviderMapping: Record<string, RouteDataProvider> = {
  customers: customersDataProvider,
  companies: companiesDataProvider,
  offsets: offsetsDataProvider,
  admins: adminsDataProvider,
};

export const mainProvider = {
  getMany: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getMany(params);
  },
  getOne: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getOne(params);
  },
  create: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.create(params);
  },
  delete: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.delete(params);
  },
  deleteMany: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.deleteMany(params);
  },
  getList: (resource, params) => {
    try {
      const provider = resourceProviderMapping[resource];

      return provider.getList(params);
    } catch (error) {
      return Promise.reject(error.message);
    }
  },
  getManyReference: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getManyReference(params);
  },
  update: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.update(params);
  },
  updateMany: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.updateMany(params);
  },
} as DataProvider;
