import React from 'react';
import { Edit, CheckboxGroupInput, SimpleForm, Toolbar, SaveButton, TextInput, required } from 'react-admin';
import { transformEnumToChoice } from '../../../utils/transform-enum-to-choice';
import { CompanyFeature } from '../../../types';

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const CompanyEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <CheckboxGroupInput source="features.allowed" choices={transformEnumToChoice(CompanyFeature)} />
        <TextInput source="features.maxSitesAmount" validate={required()} parse={val => Number(val)} />
      </SimpleForm>
    </Edit>
  );
};
