import React, { cloneElement, useEffect, useState } from 'react';
import {
  Show,
  TextField,
  TopToolbar,
  DeleteButton,
  DateField,
  TabbedShowLayout,
  Tab,
  TextInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  useEditController,
  EditContextProvider,
  required,
} from 'react-admin';
import AddCreditsButton from '../AddCreditsButton/AddCreditsButton';
import { useParams } from 'react-router-dom';
import { GetPaginatedCustomersResponseDto } from '../../../types';
import { getCompanyCustomers } from '../../../api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
const Title = ({ company }: any) => <div>Company</div>;

const Actions = ({ data, resource }: any) => (
  <TopToolbar>
    <AddCreditsButton />
    <DeleteButton record={data} resource={resource} mutationMode="pessimistic" />
  </TopToolbar>
);

function ShowCompany(props: any) {
  const editControllerProps = useEditController(props);

  const { id } = useParams<{ id: string }>();

  const [customers, setCustomers] = useState<GetPaginatedCustomersResponseDto>();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const fetchCustomers = async (page: number) => {
    try {
      const { data } = await getCompanyCustomers(id, { page, limit });
      setCustomers(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCustomers(page);
  }, [page]);

  return (
    <Show {...props} title={<Title />} actions={<Actions />} hasList>
      <TabbedShowLayout>
        <Tab label="General">
          <TextField source="name" />
          <TextField label="Wallet" source="wallet.credits" />
          <DateField source="createdAt" label="Registration date" />
        </Tab>
        <Tab label="Verified by Net0">
          <EditContextProvider value={editControllerProps}>
            {cloneElement(
              <SimpleForm
                toolbar={
                  <Toolbar>
                    <SaveButton label="Save" />
                  </Toolbar>
                }>
                <ArrayInput source="verifiedByNet0Items" resource="companies">
                  <SimpleFormIterator>
                    <TextInput label="Title" source="title" validate={required()} />
                    <TextInput label="Subtitle" source="subtitle" validate={required()} />
                    <TextInput label="Description" source="description" validate={required()} />
                  </SimpleFormIterator>
                </ArrayInput>
              </SimpleForm>,
              editControllerProps,
            )}
          </EditContextProvider>
        </Tab>
        <Tab label="Team">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers?.data.map(customer => {
                  return (
                    <TableRow key={customer.id}>
                      <TableCell>{customer.firstName}</TableCell>
                      <TableCell>{customer.lastName}</TableCell>
                      <TableCell>{customer.email}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
            count={customers?.pageCount || 1}
            page={page - 1}
            onChangePage={(event, page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={event => {
              setLimit(parseInt(event.target.value, 10));
              setPage(1);
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export default ShowCompany;
